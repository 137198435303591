exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-calculadora-tsx": () => import("./../../../src/pages/calculadora.tsx" /* webpackChunkName: "component---src-pages-calculadora-tsx" */),
  "component---src-pages-calculator-tsx": () => import("./../../../src/pages/calculator.tsx" /* webpackChunkName: "component---src-pages-calculator-tsx" */),
  "component---src-pages-form-judicial-tsx": () => import("./../../../src/pages/FormJudicial.tsx" /* webpackChunkName: "component---src-pages-form-judicial-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-page-tsx": () => import("./../../../src/pages/LandingPage.tsx" /* webpackChunkName: "component---src-pages-landing-page-tsx" */),
  "component---src-pages-survey-js": () => import("./../../../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */)
}

